:root {
  --header-top-height: 80px; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/memwYa2wxmKQyNkoTZM_ULUebck.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/memwYa2wxmKQyNkpTZM_ULUebck.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/memwYa2wxmKQyNknTZM_ULUe.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

html {
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #f9f9f9;
  background-color: #333;
  font-family: Saira, Arial, sans-serif;
  min-height: 100vh;
  position: relative; }

a, a:visited {
  color: #acf; }

a[href^="http"] {
  background-image: linear-gradient(transparent, transparent), url("data:image/svg+xml,%3C%3Fxml version=%221.0%22 encoding=%22UTF-8%22 standalone=%22no%22%3F%3E%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22%3E%3Cpath fill=%22%23fff%22 stroke=%22%2306c%22 d=%22M1.5 4.518h5.982V10.5H1.5z%22/%3E%3Cpath d=%22M5.765 1H11v5.39L9.427 7.937l-1.31-1.31L5.393 9.35l-2.69-2.688 2.81-2.808L4.2 2.544z%22 fill=%22%2306f%22/%3E%3Cpath d=%22M9.995 2.004l.022 4.885L8.2 5.07 5.32 7.95 4.09 6.723l2.882-2.88-1.85-1.852z%22 fill=%22%23fff%22/%3E%3C/svg%3E");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 13px; }

.heroouter {
  background-image: linear-gradient(#888, #333);
  height: 400px;
  width: 100%;
  margin: 10px 0 40px 0;
  padding: 0; }

.heromessage {
  height: 400px;
  width: 100%;
  padding: 0;
  text-align: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.heromessage h1 {
  color: #ffffaa;
  font-size: 1.6rem; }

.heromessage .songCard {
  opacity: 0.8; }

.logolink {
  text-align: left;
  font-size: .8rem; }

.logolink a, .logolink a:visited, logolink a:active {
  text-decoration: none;
  color: #adf; }

.v2-header {
  padding: 10px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 0.75em;
  text-align: left; }

#nav_top, .nav_main {
  max-width: 1250px;
  margin: 0 auto;
  text-align: left;
  margin: 0 auto;
  overflow: hidden;
  font-size: 0.8em;
  background-color: transparent;
  text-align: right; }

.nav_main .selected {
  display: inline-block;
  background-color: #338855;
  padding: 3px;
  margin: 2px;
  /*
	border: 1px solid #558855;
	*/
  border-radius: 3px; }

#header {
  /* background-color: seagreen; */
  background-color: #555;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative; }

.header-content {
  max-width: 1250px;
  margin: 0 auto;
  padding: 5px;
  overflow: hidden; }
  .header-content .header-content img {
    float: left;
    margin-right: 5px; }

.topnavi a {
  margin: 5px;
  color: white; }

.topnavi a:visited {
  color: white; }

.toplink {
  text-align: right;
  max-width: 1200px;
  margin: 0 auto; }

.toplink a, .toplink a:visited {
  display: inline-block;
  color: steelblue;
  margin-right: 20px; }

#footer {
  width: 100%;
  position: sticky;
  right: 0;
  top: 100vh; }

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  border-top: 2px solid silver; }

.footer-navi {
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  text-align: center; }

.footer-navi a,
.footer-navi a:visited {
  font-size: 0.75rem;
  font-size: 0.75em;
  color: #cdf;
  text-decoration: none; }

div#content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 15px;
  overflow: hidden; }

.breadcrumb {
  clear: all;
  width: 100%;
  font-size: 0.75rem;
  border-top: 1px solid #aaa;
  padding: 5px 0;
  margin: 5px 0; }

.topnavi {
  display: none;
  float: right;
  padding: 0;
  margin: 15px 30px 0 0;
  font-size: 0.75rem; }

.mobile-navi {
  display: block;
  width: 180px;
  font-size: 0.75rem;
  margin: 1px;
  padding: 3px;
  border: 1px solid silver;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: silver;
  border: 1px solid #ccc; }
  .mobile-navi .mobile-navi-content {
    color: #333; }

@media (min-width: 300px) {
  .topnavi {
    display: block; }
  .mobile-navi {
    display: none; } }

#footer {
  clear: both; }

.hero2image {
  max-height: 150px;
  width: 100%;
  overflow: hidden; }

@media (min-width: 420px) {
  .hero2image {
    max-height: 200px; } }

@media (min-width: 500px) {
  .hero2image {
    max-height: 250px; } }

@media (min-width: 720px) {
  .hero2image {
    max-height: 350px; } }

@media (min-width: 1000px) {
  .hero2image {
    max-height: 450px; } }

/* MODAL ----------------------------------------------- */
#modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #333;
  /* background-color: rgba(0,0,0,.6); */ }

#modal-content {
  background-color: #fefefe;
  color: #333;
  margin: 20% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
  max-width: 80%;
  border: 1px solid 555;
  border-radius: 20px; }

#modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold; }

#modal-close:hover,
#modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

/* PAGE MUSIC ----------------------------------------------- */
.playListItem {
  border-bottom: 1px solid #334455; }

.playListItem:hover {
  background-color: #334455; }

.playListItemSelected {
  background-color: #445555; }

.playListItem a {
  display: block; }

.albumcard {
  display: block;
  background-color: #345;
  border-radius: 15px;
  border: 1px solid #fff;
  margin: 15px;
  padding: 15px;
  overflow: hidden;
  text-align: left; }

.albumcard .albumcover {
  margin: 15px;
  float: left;
  border-radius: 15px;
  color: #f9f9f9; }

.heroimage {
  width: 100%;
  height: auto;
  margin: 50px 0; }

.coverimage-big {
  width: 90%;
  max-width: 800px;
  height: auto; }

.infoWall {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.songWall {
  max-width: 800px;
  margin: 0 auto; }

.pager {
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid silver;
  background-color: #ddd; }

.pagerbutton {
  color: #f8f8f8; }

.infoCard, .songCard {
  border: 1px solid #ddd;
  border-radius: 15px;
  color: #ffc;
  background-image: linear-gradient(-30deg, #335, #232);
  /* min-width: 258px; */
  margin: 0 10px 25px 0;
  padding: 10px;
  overflow: hidden; }
  .infoCard a, .songCard a {
    display: inline-block; }
    .infoCard a:hover, .songCard a:hover {
      /* background-image: none; */
      color: #cc0000;
      background-color: #ffc; }
  .infoCard img, .songCard img {
    vertical-align: middle; }
  .infoCard ul, .songCard ul {
    padding: 0;
    margin: 0 0 0 15px;
    list-style-type: disclosure-closed; }
  .infoCard h1, .infoCard h2, .infoCard h3, .infoCard h4, .infoCard h5, .songCard h1, .songCard h2, .songCard h3, .songCard h4, .songCard h5 {
    margin: 2px; }

.songCard {
  text-align: center;
  /* background-image: linear-gradient(-30deg, #ddddf0, #f0f0f0); */ }

.songCard img {
  vertical-align: middle;
  border-radius: 15px; }

.flexwall {
  text-align: center; }

.cover-image {
  float: right;
  overflow: hidden;
  padding: 10px;
  margin: 0 0 10px 10px;
  max-width: 50%; }
  .cover-image .imagecredit {
    float: right; }
  .cover-image img {
    margin: 1rem;
    max-width: 100%;
    height: auto; }

@media (max-width: 680px) {
  .cover-image {
    text-align: center;
    float: none;
    max-width: 90%; }
    .cover-image img {
      max-width: 90%;
      float: none; } }

.myplayer {
  position: relative;
  display: inline-block;
  margin: 20px;
  padding: 10px;
  border: 1px solid #558;
  border-radius: 10px;
  /* background-image: linear-gradient(45deg, #afbbc0, #dfefcf); */
  background-image: linear-gradient(-30deg, #335, #232);
  width: 420px;
  overflow: hidden; }
  .myplayer .myplayer-title {
    font-weight: bold; }
    .myplayer .myplayer-title img {
      width: 90px;
      height: 90px;
      border: 1px solid #555; }
  .myplayer .myplayer-image {
    width: 120px;
    height: 120px;
    overflow: hidden;
    float: left;
    margin-right: 20px; }
  .myplayer .player-imagecredit {
    font-size: 0.8em;
    color: #555;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 20px; }
  .myplayer audio {
    height: 50px;
    width: 100%;
    margin: 10px 0; }

@media (max-width: 1000px) {
  .myplayer {
    max-width: 335px; } }

@media (max-width: 840px) {
  .myplayer {
    max-width: 330px; } }

@media (max-width: 520px) {
  .myplayer {
    max-width: 80%; } }

.idx-hr {
  color: #777; }

.idx-header {
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: center; }

.idx-hero-box {
  padding: 0 10px; }

.idx-hero {
  background-image: url(/fs/photos/heroimage.jpg);
  background-position: 50%;
  background-size: cover;
  height: 500px; }

@media (min-width: 600px) {
  .idx-hero {
    height: 600px; } }

@media (min-width: 800px) {
  .idx-hero {
    height: 700px; } }

@media (min-width: 1000px) {
  .idx-hero {
    height: 1000px; } }

.idx-entry {
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  width: 100%;
  text-align: center; }

.idx-entry a {
  color: silver;
  font-size: 0.8rem; }

.idx-footer {
  width: 100%;
  position: sticky;
  right: 0;
  top: 100vh; }

.idx-footer-content {
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: center; }
